<template>
    <section class="content">
        <table class="table table-hover" ref="tableuser">
            <thead>
                <tr>
                    <th>Nama</th>
                    <th>No. HP</th>
                    <th>Kota</th>
                    <th>Alamat</th>
                    <th>Total Kiriman</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ formTitle }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Username</label>
                                    <input type="hidden" name="row_id" value="" />
                                    <input id="username" class="form-control" v-model="form.username" type="text" required="required" name="username" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Group</label>
                                    <select v-model="form.role_id" class="form-control">
                                        <option v-for="role in groups" v-bind:value="role.id">{{ role.label }}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Display Name</label>
                                    <input id="name" class="form-control" v-model="form.display_name" type="text" required="required" name="i_name" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Email</label>
                                    <input id="email" class="form-control" v-model="form.email" type="text" required="required" name="i_email" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Password</label>
                                    <input id="pwd" class="form-control" value="" type="password" name="i_pwd" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label"> </label>
                                    <label class="checkbox control-label">
                                        <input value="1" checked="checked" type="checkbox" name="active" /> Active</label>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save changes</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import $ from "jquery";

export default {
  name: "Shipper",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      groups: [],
      formTitle: "Tambah Shipper",
      form: {
        name: "",
        username: "",
        email: "",
      },
    };
  },
  computed: {
    kurirLogo() {
      const fileName = "jne"; //logo.toLowerCase()

      return require(`../assets/img/${fileName}.png`); // the module request
    },
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
    //console.log('load initial data')
    authFetch("/users/groups")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.groups = js.data;
      });
  },
  methods: {
    getKurirLogo(kurir) {
      const fileName = kurir.toLowerCase();

      return require(`../assets/img/${fileName}.png`); // the module request
    },
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/users";
      if (this.method == "PUT") urlSubmit = "/users/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    var self = this;
    this.table = createTable(e.tableuser, {
      title: "Data Buyer",
      roles: this.$route.params.roles,
      ajax: "/shipper/buyer",
      frame: true,
			scrollX: true,
			autoWidth: false,
      lengthChange: false,
      filter: false,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      columns: [
        { data: "receiver_name" },
        { data: "receiver_phone" },
        { data: "kota" },
        { data: "receiver_address" },
        { data: "cnt" },
      ],
      filterBy: [1, 2],
      rowCallback: function (row, data) {
        $("td:eq(4)", row).html(
          '<span class="label label-success">' + data.cnt + "</span>"
        );
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah User";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit User";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          self.form = evt.data;
          authFetch("/users/" + evt.data.id, {
            method: "DELETE",
            body: "id=" + evt.data.id,
          })
            .then((res) => {
              return res.json();
            })
            .then((js) => {
              this.table.api().ajax.reload();
            });
        }
      },
      initComplete: function () {
        $('.loading-overlay').removeClass('show');
      },
    });
  },
};
</script>